import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

/**
 * Renders information about the user obtained from MS Graph
 * @param props
 */
export const ProfileData = ({ graphData }) => {
    const [newPhoto, setNewPhoto] = useState(null);

    console.log(graphData);

    const uploadNewPhoto = async () => {
        console.log(newPhoto);
        const res = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${graphData.token}`,
                'Content-Type': newPhoto.type,
            },
            body: await newPhoto.arrayBuffer(),
        });
        if (!res.ok) {
            alert('Error uploading new profile picture. Please try again.');
            console.error(await res.json() || res.status);
        } else {
            window.location.reload();
        }
    }

    return (
        <div id="profile-div">
            <div className="mb-4 d-flex flex-row align-items-center gap-4">
                <div className="overflow-hidden flex-shrink-0 shadow"
                     style={{ borderRadius: '1rem', width: '50%', maxWidth: '200px' }}>
                    <img src={graphData.photo} alt="Profile picture" style={{ width: '100%' }} />
                </div>
                <div>
                    <h1 className="fw-bold">{`${graphData.givenName} ${graphData.surname}`}</h1>
                    <p>{graphData.userPrincipalName}</p>
                    <p>ID: {graphData.id}</p>
                </div>
            </div>
            <hr />
            <Form>
                <legend className="fw-bold">Update profile</legend>
                <FormGroup controlId="profilePhotoUpdate" className="mb-4">
                    <FormLabel>Profile photo</FormLabel>
                    <FormControl
                        type="file"
                        // value={newPhoto}
                        onChange={(e) => setNewPhoto(e.target.files[0])}
                    />
                </FormGroup>
                <Button variant="dark" onClick={uploadNewPhoto}>Update</Button>
            </Form>
        </div>
    );
};