import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        }).then(async (response) => {
            const graphRes = await callMsGraph(response.accessToken)
                .then((res) => res.json());
            const photo = await callMsGraph(response.accessToken, 'https://graph.microsoft.com/v1.0/me/photo/$value')
                .then((res) => res.blob())
                .then((blob) => URL.createObjectURL(blob));
            setGraphData({
                token: response.accessToken,
                photo,
                ...graphRes,
            })
        });
    }, []);

    return (
        <>
            {graphData ?
                <>
                    {/*<h5 className="card-title">Welcome, {accounts[0].name.split(" ")[0]}!</h5>*/}
                    <ProfileData graphData={graphData} />
                </>
                :
                <p>Loading...</p>
            }
        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is
 * not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
